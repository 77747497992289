<template>
  <b-container fluid>
    <b-form-row class="h-100 justify-content-center">
      <b-col cols="auto">
        <b-card class="auth-form">
          <b-form class="form text-center" @submit.prevent="login">
            <img
              class="mb-3 img-fluid d-lg-none"
              src="@/assets/logo.png"
              width="120"
            />
            <h3 class="mb-3 font-weight-bolder">Welcome back!</h3>

            <b-form-group>
              <b-form-input
                trim
                type="email"
                v-model="$v.form.body.email.$model"
                :state="validateState('email')"
                placeholder="Email Address"
                aria-described-by="email-feeback"
              >
              </b-form-input>
              <b-form-invalid-feedback id="email-feedback"
                >{{ this.form.errors.email }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group class="mb-4">
              <b-input-group>
                <b-form-input
                  trim
                  :type="showpwd ? 'text' : 'password'"
                  placeholder="Password"
                  v-model="$v.form.body.password.$model"
                  :state="validateState('password')"
                  aria-described-by="password-feeback"
                >
                </b-form-input>
                <b-input-group-append @click="showpwd = !showpwd">
                  <b-input-group-text class="bg-white" style="cursor: pointer">
                    <b-icon :icon="showpwd ? 'eye-slash' : 'eye'" />
                  </b-input-group-text>
                </b-input-group-append>
                <b-form-invalid-feedback id="password-feedback"
                  >{{ this.form.errors.password }}
                </b-form-invalid-feedback>
              </b-input-group>
            </b-form-group>
            <b-button variant="dark" block type="submit"> SIGN IN </b-button>
          </b-form>

          <b-button
            size="sm"
            variant="link"
            class="float-right pt-3 text-dark font-weight-bold text-decoration-none"
            @click="$router.push('/forgot-password')"
            >Forgot Password?</b-button
          >
          <b-overlay :show="isBusy" no-wrap></b-overlay>
        </b-card>
      </b-col>
    </b-form-row>
  </b-container>
</template>

<script>
import errorMixins from "../../mixins/notifications/error";
import successMixins from "../../mixins/notifications/success";
import { required, email, minLength } from "vuelidate/lib/validators";

export default {
  name: "LoginPage",
  mixins: [errorMixins, successMixins],
  data() {
    return {
      isBusy: false,
      showpwd: false,
      otpMessage: "",
      verificationToken: null,
      form: {
        body: {
          email: "",
          password: "",
        },
        errors: {
          email: "",
          password: "",
        },
      },
    };
  },
  validations: {
    form: {
      body: {
        email: {
          required,
          email,
        },
        password: {
          required,
          minLength: minLength(6),
        },
      },
    },
  },
  methods: {
    login() {
      this.$v.form.body.$touch();

      this.setErrors();

      if (!this.$v.form.body.$anyError) {
        this.isBusy = true;
        this.$store
          .dispatch("auth/login", {
            body: { ...this.form.body },
            fetchUser: false,
          })
          .then(({ data, message }) => {
            if (data) {
              this.$auth.user(data.user);
              this.success();
            }
          })
          .catch((error) => {
            this.errorHandler(error.response);
          })
          .finally(() => {
            this.isBusy = false;
          });
      }
    },

    validateState(name) {
      const { $dirty, $error } = this.$v.form.body[name];
      return $dirty ? !$error : null;
    },
    setErrors() {
      // Gathering the vuelidate error objects.
      const email = this.$v.form.body.email;
      const password = this.$v.form.body.password;

      // Checking which fields are invalid.
      if (!email.email) {
        this.form.errors.email = "The email address you entered is invalid.";
      }

      if (!email.required) {
        this.form.errors.email = "An email address is required.";
      }

      if (!password.required || !password.minLength) {
        this.form.errors.password =
          "Please enter a password of at least 6 characters.";
      }
    },

    success() {
      const redirect = this.$auth.redirect();

      if (
        redirect &&
        redirect.to &&
        redirect.to.name === "Login" &&
        redirect.from.name
      ) {
        this.$router.push({ name: redirect.from.name });
      } else {
        this.$router.push({ name: "Home" });
      }

      this.$swal({
        icon: "success",
        titleText: "Login successful",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
      });
    },
  },
};
</script>
